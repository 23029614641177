import React, {useEffect, useLayoutEffect, useState, useCallback, useRef} from "react";
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import Moment from 'moment';
import { v4 as uuid } from 'uuid';

import './App.css';


import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";

import CircularProgress from "@mui/material/CircularProgress";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";


import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';


import {useTemplate} from "./Hooks/useTemplate";

import fileDownload from 'js-file-download'
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const App = () => {

    /**
     * Хук для работы с шаблонами фрагментов документа
     * @type Object
     */
    const templates = useTemplate();

    /**
     * Префикс кода
     * @type {string}
     */
    const documentCodePrefix = '00-GEN-0001-';

    /**
     * Файл
     */
    const [sourceFile, setSourceFile] = useState(
        {}
    );

    /**
     * Состояние загрузки файла
     */
    const [isUploading, setIsUploading] = useState(
        false
    );

    /**
     * Состояние обработки файлов
     */
    const [isProcessing, setIsProcessing] = useState(
        false
    );

    /**
     * Обработчик события выбора файла
     */
    const handleFileChange = event => {
        try {

            /**
             * Состояние загрузки
             */
            setIsUploading(true);

            /**
             * Загружаемый файл
             */
            const uploadedFile = event.target.files[0];

            /**
             * Чтение файла
             * @type {FileReader}
             */
            const reader = new FileReader();

            /**
             * Обработчик события загрузки файла
             * @param event
             */
            reader.onload = (event) => {

                /**
                 * Коллекция полей
                 */
                const fields = [];

                /**
                 * Объект парсера
                 * @type {DOMParser}
                 */
                const parser = new DOMParser();

                /**
                 * Объект для работы с базой
                 * @type Object
                 */
                const moment = Moment();

                /**
                 * Источник документа
                 * @type {Document}
                 */
                const source = parser.parseFromString(
                    reader.result, 'text/xml'
                );

                /**
                 * Массив с уникальными заголовками полей
                 * @type {*[]}
                 */
                let labels = [];

                /**
                 * Парсинг документа
                 */
                for (let entry of source.querySelectorAll('entry')) {
                    /**
                     * Определение обязательности блока
                     * @type {boolean}
                     */
                    let required = false;

                    try {
                        required = entry.previousSibling.previousSibling.textContent.indexOf('R [') !== -1
                    } catch (e) {
                    }

                    /**
                     * Обработка полей в блоке
                     */
                    for (let field of entry.querySelectorAll('observation > code')) {
                        if (!field.getAttribute('codeSystem')) {
                            continue;
                        }

                        if (!labels.includes(field.getAttribute('displayName')) ) {
                            const isRelation = field.getAttribute('codeSystem') ? (field.getAttribute('codeSystem').indexOf('.') !== -1 && field.getAttribute('codeSystem') !== '1.2.643.5.1.13.13.99.2.166') : false;

                            fields.push(
                                Object.assign(
                                    {
                                        id: uuid(),
                                        type: isRelation ? 'relation' : 'text',
                                        checked: true,
                                        required: required,
                                        label: {
                                            actual: field.getAttribute('displayName'), candidate: field.getAttribute('codeSystemName')
                                        }
                                    }, isRelation ? {
                                        relation: field.getAttribute('codeSystem'),
                                    } : {}
                                )
                            );

                            labels = [
                                ...labels, field.getAttribute('displayName'), field.getAttribute('codeSystemName')
                            ];
                        }
                    }
                }



                /**
                 * Устанавливает состояние с данными файла
                 */
                setSourceFile(
                    {
                        name: uploadedFile.name,
                        fields: fields,
                        meta: {
                            name: source.querySelector('ClinicalDocument > title').textContent ?? 'Протокол',
                            code: documentCodePrefix + moment.unix().toString().substring(0, 6)
                        }
                    }
                );

                /**
                 * Завершена загрузка
                 */
                setIsUploading(false);
            };

            /**
             * Чтение выбранного файла
             */
            reader.readAsText(
                uploadedFile, 'UTF-8'
            );

        } catch (e) {
        }
    };

    /**
     * Обработчик события
     */
    const handleProcessStart = () => {
        /**
         * Установка признака парсинга документа
         */
        setIsProcessing(true);

        /**
         * Объект для транслитерации текста
         * @type Object
         */
        const transliter = new CyrillicToTranslit();

        /**
         * Документ
         * @type {Document}
         */
        const doc = document.implementation.createDocument("", "", null);

        /**
         * Структура документа
         * @type {ChildNode}
         */
        const form = templates.get('form');

        /**
         * Смена наименования документа
         * @type {string}
         */
        form.getElementsByTagName("NAME")[0].textContent = sourceFile.meta.name;

        /**
         * Индекс элемента
         * @type {number}
         */
        let count = 1;

        /**
         * Подготовка групп
         */
        sourceFile.fields.filter(field => field.checked).map((field, i) => {
            /**
             * Создаем группу
             * @type Object
             */
            const group = templates.get('group', i + 1);

            form.appendChild(group);
            count++;
        });

        /**
         * Подготовка элементов
         */
        sourceFile.fields.filter(field => field.checked).map((field, i) => {
            /**
             * Имя поля
             * @type {string}
             */
            let name = 'f' + i + '_' + transliter.transform(field.label.actual, '_').toLowerCase().substr(0, 20).replace(/[^a-zA-Z0-9]/g, '_');

            /**
             * Создаем Надпись
             */
            /*const label = templates.get('label', count);

            label.setAttribute('PARENT', 'group_' + (i + 1));
            label.setAttribute('nadpis_text', field.label.actual);

            form.appendChild(label);
            count++;*/

            /**
             * Создаем элемент
             */
            const input = templates.get('input', count);

            input.setAttribute('PARENT', 'group_' + (i + 1));
            input.setAttribute('TYP', field.relation ? '4' : '5');
            input.setAttribute('tablename', field.relation ? 'meta_' + field.relation : '');
            input.setAttribute('nadpis_text', field.label.actual);
            input.setAttribute('obiazatpole', field.required ? '1' : '0');
            input.setAttribute('LIST_OF_DOT', 'Element_' + count);

            input.setAttribute('prog_tree_element_name', name);
            input.setAttribute('user_tree_element_name', name);
            input.setAttribute('reg_name', name);

            form.appendChild(input);
            count++;

        });

        /**
         * Добавление корневого элемента в документа
         */
        doc.appendChild(form);

        /**
         * Скачивание файла
         */
        fileDownload(
            new XMLSerializer().serializeToString(doc.documentElement), sourceFile.meta.code + '.xml'
        );

        /**
         * Актуализация статуса
         */
        setIsProcessing(false);
    };

    /**
     * Обработчик события смены наименования документа
     * @param e
     */
    const handleDocumentNameChange = (e) => {
        setSourceFile(
            prevState => {
                return {
                    ...prevState, meta: {
                        ...prevState.meta, name: e.target.value
                    }
                }
            }
        );
    };

    /**
     * Обработчик события смены кода документа
     * @param e
     */
    const handleDocumentCodeChange = (e) => {
        setSourceFile(
            prevState => {
                return {
                    ...prevState, meta: {
                        ...prevState.meta, code: e.target.value
                    }
                }
            }
        );
    };

    /**
     * Обраюотчик события клика на поле
     * @param e
     * @param id
     */
    const handleFieldCheckboxClick = (e, id) => {
        setSourceFile(prevState => {
            prevState.fields.map((field, i) => {
                if (field.id === id) {
                    return {
                        fields: {
                            ...prevState.fields[i].checked = e.target.checked
                        }
                    };
                }
            });

            return {
                ...prevState,
            }
        })
    };


    /**
     * Обработчик события смены надписи
     * @param e
     * @param id
     */
    const handleFieldChangeLabelClick = (e, id) => {
        setSourceFile(prevState => {
            prevState.fields.map((field, i) => {
                if (field.id === id) {
                    return {
                        fields: {
                            ...prevState.fields[i].label = {
                                actual: field.label.candidate, candidate: field.label.actual
                            }
                        }
                    };
                }
            });

            return {
                ...prevState,
            }
        })
    };

    /**
     * Рендер
     */
    return (
        <>
            <Container component="main" maxWidth="md">
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: "100vh"}}>
                    {
                        (() => {
                            switch (true) {
                                case Object.keys(sourceFile).length !== 0 : {
                                    return <Box>
                                        <div className="info">
                                            <div>
                                                Имя файла: {sourceFile.name}
                                            </div>
                                            <div>
                                                Полей: {sourceFile.fields.length}
                                            </div>
                                        </div>

                                        <Box sx={{mb: '1rem'}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={8}>
                                                    <TextField sx={{width: '100%'}}
                                                               defaultValue={sourceFile.meta.name}
                                                               label="Имя шаблона"
                                                               onChange={(e) => handleDocumentNameChange(e)}
                                                               variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField sx={{width: '100%'}}
                                                               defaultValue={sourceFile.meta.code}
                                                               label="Код шаблона"
                                                               onChange={(e) => handleDocumentCodeChange(e)}
                                                               variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box sx={{mb: '1rem'}}>
                                            <Paper sx={{maxHeight: 350, overflow: 'auto'}}>
                                                <List sx={{p: 0}}>
                                                    {
                                                        sourceFile.fields.map((field, key) => {
                                                            const labelId = `checkbox-list-label-${key}`;

                                                            return (
                                                                <ListItem key={key} secondaryAction={
                                                                    <IconButton onClick={(e) => handleFieldChangeLabelClick(e, field.id)}>
                                                                        <PublishedWithChangesIcon/>
                                                                    </IconButton>
                                                                }>

                                                                    <ListItemIcon sx={{minWidth: '36px'}}>
                                                                        <Checkbox
                                                                            onChange={(e) => handleFieldCheckboxClick(e, field.id)}
                                                                            edge="start"
                                                                            checked={field.checked ?? false}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <Box>
                                                                        <Box sx={{fontSize: 15, mb: '.25rem'}}>
                                                                            {field.label.actual} {field.required ? '*' : ''}
                                                                        </Box>

                                                                        {/*<Link component="button" underline="none" onClick={(e) => handleFieldChangeLabelClick(e, field.id)}>
                                                                            {field.label.candidate}
                                                                        </Link>*/}

                                                                        <Box sx={{color: '#aaa', fontSize: 12, lineHeight: '16px'}} >
                                                                            {field.label.candidate}
                                                                        </Box>
                                                                    </Box>
                                                                </ListItem>
                                                            );
                                                        })
                                                    }
                                                </List>
                                            </Paper>
                                        </Box>

                                        <Box>
                                            <Stack direction="row" spacing={1}>
                                                <Button variant="outlined" component="label">
                                                    Выбрать другой файл
                                                    <input
                                                        onChange={(event) => handleFileChange(event)}
                                                        hidden
                                                        type="file"
                                                    />
                                                </Button>

                                                <Button variant="contained" onClick={() => handleProcessStart()}>
                                                    Начать обработку
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </Box>
                                }
                                default : {
                                    return <Box>
                                        {
                                            isUploading ?
                                                <CircularProgress/> :
                                                <Button variant="contained" component="label">
                                                    Выберите файл для обработки
                                                    <input
                                                        onChange={(event) => handleFileChange(event)}
                                                        hidden
                                                        type="file"
                                                    />
                                                </Button>
                                        }

                                    </Box>
                                }
                            }
                        })()
                    }
                </Box>
            </Container>
        </>
    );
}

export default App;