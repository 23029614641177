/**
 * Импорт шаблонов
 */
import templates from "../templates"

/**
 * Хук работы с шаблонами
 * @returns Object
 */
export const useTemplate = () => {

    /**
     * Функция для получения данных шаблона
     * @returns {Object}
     * @param name
     * @param num
     */
    const get = (name, num) => {

        /**
         * Объект парсера
         * @type {DOMParser}
         */
        const parser = new DOMParser();

        /**
         * Чтение шаблона
         * @type {Document}
         */
        const doc = parser.parseFromString(templates[name].replaceAll("%NUM%", num), 'text/xml');

        return doc.documentElement;
        /**
         * Подготовка шаблона
         */
        return doc.querySelector('template').firstChild;
    };

    return {get};
};
