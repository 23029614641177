import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

/**
 * Инициализация приложения
 */
const startApp = () => {
    ReactDOM
        .createRoot(document.getElementById('root'))
        .render(
            <React.StrictMode>
                <App />
            </React.StrictMode>
        );
};

startApp();
reportWebVitals();
